// 配置API接口地址
//var root = process.env.BASE_URL
var root = "/"
if(window.location.host=="web.megacash.lk"|| window.location.host=="www.megacash.lk"){
	root =	'https://api.megacash.live/'
}else{
	root = 'https://api.megacash.live/'

}
// 引用axios
var axios = require('axios')
var qs = require('qs')

// 自定义判断元素类型JS
function toType (obj) {
  return ({}).toString.call(obj).match(/\s([a-zA-Z]+)/)[1].toLowerCase()
}

// 参数过滤函数
function filterNull (o) {
  for (var key in o) {
    if (o[key] === null) {
      delete o[key]
    }
    if (toType(o[key]) === 'string') {
      o[key] = o[key].trim()
    } else if (toType(o[key]) === 'object') {
      o[key] = filterNull(o[key])
    } else if (toType(o[key]) === 'array') {
      o[key] = filterNull(o[key])
    }
  }
  return o
}

// 在封装axios的文件中添加拦截器
// 添加请求拦截器，在请求头中加token
axios.interceptors.request.use(
 config => {
 // 判断本地的cookie中是否有token
 if (localStorage.getItem('token')) {
  config.headers.Authorization = localStorage.getItem('token')
 } else {
 // 跳转到登录页面(这里使用router，是因为路由文件引入到了此文件里)
 //router.push('/login')
 }
 if (localStorage.getItem('lang')) {
  config.headers.language = localStorage.getItem('lang')
 } else {
 // 跳转到登录页面(这里使用router，是因为路由文件引入到了此文件里)
 //router.push('/login')
  config.headers.language = 'en'
 }
   config.headers['Device-Type'] = 'PC';
 if(localStorage.getItem('place')){
   config.headers.Place = localStorage.getItem('place');
 }else{
   config.headers.Place = 'PC';
 }
 return config
 },
 error => {
 return Promise.reject(error)
 })

function apiAxios (method, url, params, success, failure) {
  if (params) {
    params = filterNull(params)
  }
  if(method == 'POST' && !(params instanceof FormData)){
    params = qs.stringify(params);//数据参数格式转换
  }
  //console.log(params)
  axios({
    method: method,
    url: url,
    data: method === 'POST' || method === 'PUT' ? params : null,
    params: method === 'GET' || method === 'DELETE' ? params : null,
    baseURL: root
   // withCredentials: true
  })
  .then(function (res) {
    if (res.data) {
      if (success) {
        success(res.data)
      }
    } else {
      if (failure) {
        failure(res.data)
      } else {

      }
    }
  })
  .catch(function (err) {
    let res = err.response
    if (err) {
     // window.alert('api error, HTTP CODE: ' + res.status)
    }
  })
}

//返回在vue模板中的调用接口
export default {
  get: function (url, params, success, failure) {
    return apiAxios('GET', url, params, success, failure)
  },
  post: function (url, params, success, failure) {
    return apiAxios('POST', url, params, success, failure)
  },
  put: function (url, params, success, failure) {
    return apiAxios('PUT', url, params, success, failure)
  },
  delete: function (url, params, success, failure) {
    return apiAxios('DELETE', url, params, success, failure)
  }
}
