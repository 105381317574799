export default {
    data() {
        return {
            aletrTimer:''
        }
    },
    alertText (text,width,time) {
        $(".alertText").remove();
        clearTimeout(this.aletrTimer);
        let html = '<div class="alertText app-en ac" style="width:'+width+'%;"><div class="in">'+text+'</div></div>';
        $("body").append(html);
        if(time == undefined){
            time = 2000;
        }
        this.aletrTimer = setTimeout(() => {
            $(".alertText").remove();
        }, time);
    },
	getDate(){
		var aData = new Date();
		//console.log(aData) //Wed Aug 21 2019 10:00:58 GMT+0800 (中国标准时间)
		return  aData.getFullYear() + "/" + (aData.getMonth() + 1) + "/" + aData.getDate();
	},
  spot(price){
    return String(price).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  },
    hidePhone (value){
        if(!value){
            return ''
        }
        var regPhone = /^(\d{3})\d+(\d{4})$/;
        return value .trim().replace(regPhone, "$1****$2")
    },
}
